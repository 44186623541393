import React from "react";
import { graphql } from "gatsby";
import Layout from '../components/layout';
import ReactHtmlParser from 'react-html-parser';

const IrPost = ({ data }) => {
    const { Title, HtmlBody, Files } = data.releases;

    return (
        <Layout>
            <div className="containerWrapper">
                <div className="wrapperPage">
                    <>
                    <h1>{Title}</h1>
                    <div className="pressPost">
                        {ReactHtmlParser(HtmlBody)}
                    </div>
                    {Files && Files.length > 0 && (
                        <div className="pdfLinks">
                            <h4>Downloadable Files:</h4>
                            <ul>
                                {Files.map((file, index) => (
                                    <li key={index}>
                                        <a href={file.Url} target="_blank" rel="noopener noreferrer">
                                            {file.Title}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                    </>
                </div>
            </div>
        </Layout>
    );
};

export const query = graphql`
    query($slug: String) {
        releases(EncryptedId: { eq: $slug }) {
            Title
            HtmlBody
            Files {
                Url
                Title
            }
        }
    }
`;

export default IrPost;